const policy = [
  {
    title: "",
    text: "This Privacy Policy describes our policies and procedures on the (non)collection, use and disclosure of your information when you use the Messo service and tells you about your privacy rights and how the law protects you. We do not use your Personal data to provide and improve the service. By using the service, you agree to the very limited collection and use of information in accordance with this Privacy Policy. The sole purpose of showing this Privacy Policy is to show a comparison with other services and show you clearly that we do not ever store any data about you on our servers.",
  },
  {
    title: `Interpretation`,
    text: `The following definitions shall have the same meaning
 regardless of whether they appear in singular or in plural.`,
  },
  {
    title: `Definitions`,
    text: `For the purposes of this Privacy Policy:`,
    bullets: [
      `Account means a unique account 
created for you to access our service or parts of our service.`,
      `Affiliate means an entity that controls, is controlled by or 
is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other 
securities entitled to vote for election of directors or other managing authority.`,
      `Application refers to Messo, the software 
program provided by the Company.`,
      `Company (referred to as either "the Company", "We", "us" or "our" in this Agreement) refers 
to The Mejlero Company GmbH, Blumenweg 12 Engelberg, OBWALDEN 6390 Switzerland.`,
      `Country refers to: Switzerland`,
      `Device means 
any device that can access the service such as a computer, a cellphone or a digital tablet.`,
      `Personal Data is any information 
that relates to an identified or identifiable individual.`,
      `service refers to the Application.`,
      `service Provider means any
 natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals 
 employed by the Company to facilitate the service, to provide the service on behalf of the Company, to perform services 
 related to the service or to assist the Company in analyzing how the service is used.`,
      `usage Data refers to data collected 
 automatically, either generated by the use of the service or from the service infrastructure itself (for example, the
     duration of a page visit).`,
      `you means the individual accessing or using the service, or the company, or other 
     legal entity on behalf of which such individual is accessing or using the service, as applicable.`,
    ],
  },
  {
    title: `Personal Data`,
    text: `While using our service, We do not ask you to provide us with any certain personally 
     identifiable information that can be used to contact or identify you. Personally identifiable information may
      include, but is not limited to user data`,
  },
  {
    title: `Usage Data`,
    text: `Usage Data is not collected using the service. Ever.
Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address), browser type,
 browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
When you access the service by or through a mobile device, We do not collect certain information automatically, including,
 but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
We do also not collect information that your browser sends whenever you visit our service or when you access the service by or through a
 mobile device.`,
  },
  ,
  {
    title: `Information collected while using the application`,
    text: `While using our application, in order to provide features of our application, we do not collect, information regarding your location and pictures and other information from your device's camera and photo library
We do not use this information to provide features of our service, to improve and customize our service (which is usually a lie from companies trying to hide the fact that they spy on you). The information may temporarily be uploaded to the company's servers and/or a service Provider's server until it's stored on your device. We do not permanently store your data in the cloud.
As there is nothing to enable or disable regarding the access to this information we do not have, you don't need to do anything at any time.`,
  },
  {
    title: `Use of your Personal Data`,
    text: `The company will never ever use your Personal Data, not even for the following purposes:`,
    bullets: [
      `To provide and maintain our service, including to monitor the usage of our service.`,
      `To manage your Account: to manage your registration as a user of the service. The Personal Data you provide can not give you access to different functionalities of the service that are available to you as a registered user.`,
      `For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the service.`,
      `To contact you: To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as informative communications related to the functionalities, products or
  contracted services, including the security updates, when necessary or reasonable for their implementation.`,
      `To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.`,
      `To manage your requests: To attend and manage your requests to us.`,
      `For business transfers: We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our service users is among the assets transferred.`,
      `For other purposes: We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our service, products, services, marketing and your experience.`,
    ],
    text: `The company will use some services to achieve these purposes:`,
    bullets: [
      `Use electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or
  contracted services, including the security updates, when necessary or reasonable for their implementation.`,
    ],
  },
  {
    text: `We will never ever share your personal information in the following or any other situations (because we don't have any personal information about you at all):`,
    bullets: [
      `With service Providers: We will never share your personal information with service Providers to monitor and analyze the use of our service, to contact you.`,
      `For business transfers: We will never share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.`,
      `With Affiliates: We will never share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with us.`,
      `With business partners: We will never share your information with our business partners to offer you certain products, services or promotions.`,
      `With other users: when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.`,
      `With your consent: We will never disclose your personal information for any other purpose with or without your consent.`,
    ],
  },
  {
    title: `Retention of your Personal Data`,
    text: `The Company will not retain your Personal Data only for any time period as it's not necessary for the purposes set out in this Privacy Policy. We will not retain and use your Personal Data to the extent necessary to comply with our legal obligations (because we have none) (for example, we are not required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
      The Company will also never ever retain usage Data for internal analysis purposes. usage Data is never retained even for a shorter period of time, not even when this data is used to strengthen the security or to improve the functionality of our service, or We are legally obligated to retain this data for longer time periods. (because we don't have the data.)`,
  },
  {
    title: `Transfer of your Personal Data`,
    text: `Your information, including Personal Data, is not processed at the company's operating offices or in any other places where the parties involved in the (non-existing) processing are located. It means that this information will never ever be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
      your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
      The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data (because we don't have the data) will take place to an organization or a country not even when there are adequate controls in place including the security of your data and other personal information.`,
  },

  {
    title: `Delete your Personal Data`,
    text: `You don't need to user your right to delete or request that we assist in deleting the Personal Data that we have collected about you, because we collect none.
      Our service may give you the ability to delete certain information from within the service.
      You may update, amend, or delete your information at any time by using your Account, and visiting the account settings section that allows you to manage your information. you do not need to contact us to request access to, correct, or delete any personal information that you have provided to us, because you haven't.
      Please note, however, that we do not need to retain certain information as we do not have a legal obligation or lawful basis to do so.`,
  },

  {
    title: `Business Transactions`,
    text: `If the company is involved in a merger, acquisition or asset sale, your Personal Data will not be transferred, as we don't have it. We will will not provide notice before your Personal Data is transferred, because we don't have it so it can never become a subject to a different Privacy Policy.`,
  },
  {
    title: `Law enforcement`,
    text: `Under certain circumstances, the company may be required to disclose logs if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). These logs do not have any of your data, usage or any other personal identifiable data at all.`,
  },
  {
    title: `Other legal requirements`,
    text: `The company will not disclose your Personal Data in the good faith belief that such action is necessary to, becase we don't have the data:`,
    bullets: [
      `Comply with a legal obligation`,
      `Protect and defend the rights or property of the company`,
      `Prevent or investigate possible wrongdoing in connection with the service`,
      `Protect the personal safety of users of the service or the public`,
      `Protect against legal liability`,
    ],
  },
  {
    title: `Security of your Personal Data`,
    text: `The security of your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, We cannot guarantee its absolute security.`,
  },
  {
    title: `Children's Privacy`,
    text: `Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under or over the age of 13. If you are a parent or guardian and you think that your child has provided us with Personal Data, we assure you that it's not possible. If we become aware that we have collected Personal Data from anyone under or over the age of 13 without verification of consent, we take steps to remove that information from our servers (where wo do not store anything).

      If We need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, We may require your parent's consent before we collect and use that information.`,
  },
  {
    title: `Links to Other Websites`,
    text: `Our service may contain links to other websites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
      We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`,
  },
  {
    title: `Changes to this Privacy Policy`,
    text: `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      We will not let you know via email and/or a prominent notice on our service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
      You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`,
  },
  {
    title: `Contact us`,
    text: `If you have any questions about this Privacy Policy, you can contact us by visiting this page on our website: https://messo.io/`,
  },
];
export default policy;
