import css from "./SupportScreen.module.css";
import Footer from "../../components/Footer/footer";
import Acordion from "../../components/Acordion";
const faq = [
  {
    header: "How do I create an account?",
    body: "You don’t need a special account or login with email. Your account is only based on an ID number which you will share with others.",
  },
  {
    header: "What if I lose my ID can you then restore my messages?",
    body: "No, if you lose your ID we can’t restore anything. Your messages and history is only stored on your device and the one you were communicating with’s device. We don’t store the information in the cloud except from the transfer, which is then deleted right after, without any backups.",
  },
  {
    header: "Can you read my messages?",
    body: "No, we don’t store the information in the cloud except from the transfer, which is then deleted right after, without any backups. Also during transfer, everything is encrypted.",
  },
  {
    header: "Can anyone read my messages?",
    body: "The only way to read your messages is on your phone or your counterparts phone.",
  },
  {
    header: "How do I delete my data?",
    body: "We do not have any data about you stored in the cloud, so there is nothing to delete. Deleting the data on your phone can be done in the app.",
  },
  {
    header: "How do I invite others?",
    body: "You can use the share function in the app which shared your ID.",
  },
  {
    header: "Is the profile picture shared in the cloud?",
    body: "No, it’s only used during transfer, then it’s deleted. We don’t store any data in the cloud.",
  },
  {
    header: "Who can message me?",
    body: "Anyone who has your ID, so everyone you shared it with, and they shared with others.",
  },
  {
    header:
      "Do I need an email, phone number or any other identification data?",
    body: "No, Messo doesn’t use any of that.",
  },
  {
    header: "What do you mean with “Privacy by design”?",
    body: "Basically our systems are built in a way so we (or anyone) can see your data, because it’s not stored anywhere.",
  },
  {
    header: "How do I recover my data?",
    body: "If you made your own backup copy (Settings > Save or share DB) you can restore this on your phone (Setings > Import DB). If you haven’t - we can’t restore your data as we don’t have it.",
  },
  { header: "Do you support Dark mode?", body: "Yes" },
  {
    header: "I clicked log out in the app, now everything is gone! ",
    body: "If you have a copy of your ID and a passcode, you can use it to login again. If not, all is lost. You will need to start with a new ID. ",
  },
  {
    header: " How do I get a new ID?",
    body: "Click on your profile picture and in the top right corner there is a log out icon. If you log out, you will get a new generated one when logging in again. Remember to store the ID and the passcode if you happen to logout again, or else all your information will be lost. ",
  },
];
export default function SupportScreen() {
  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h1 className={css.header}>Support</h1>
      </div>
      <div className={css.acordionContainer}>
        <Acordion list={faq} />
      </div>
      <Footer title="Home" path="/" />
    </div>
  );
}
