const terms = [
  {
    text: "Please read this End User License Agreement (`Agreement`) carefully before using our [Product/Service] (`the Software`). By using the Software, you agree to be bound by the terms and conditions set forth in this Agreement.",
  },
  {
    text: "License Grant",
    bullets: [
      `Subject to the terms and conditions of this Agreement, Mejlerö Company GmbH grants you a limited, non-exclusive, revocable license to use the Software solely for your personal or internal business purposes.`,
      ` You shall not sublicense, sell, lease, or otherwise transfer the Software to any third party without prior written consent from Mejlerö Company GmbH.`,
    ],
  },
  {
    text: "User Conduct",
    bullets: [
      `You agree not to use the Software for any unlawful purpose or in any way that may impair the functionality of the Software.`,
      `You acknowledge that Mejlerö Company GmbH has a zero-tolerance policy for objectionable content, including but not limited to, content that is illegal, defamatory, obscene, harassing, or infringing on intellectual property rights.`,
      ` You agree not to engage in any abusive, offensive, or harmful behavior towards other users or Mejlerö Company GmbH staff. This includes, but is not limited to, harassment, discrimination, and the use of hate speech.
`,
    ],
  },
  {
    text: "Content Standards",
    bullets: [
      ` You are solely responsible for the content you create, upload, or share using the Software.
`,
      `You agree not to upload or distribute any content that is objectionable or violates the rights of others, including intellectual property rights.
`,
    ],
  },
  {
    text: "Termination",
    bullets: [
      `Mejlerö Company GmbH reserves the right to terminate or suspend your access to the Software, with or without notice, for any violation of this Agreement.
`,
      `Upon termination, you must cease all use of the Software and destroy any copies in your possession.
`,
    ],
  },
  {
    text: "Disclaimer of Warranty",
    bullets: [
      `The Software is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
`,
    ],
  },
  {
    text: "Limitation of Liability",
    bullets: [
      `In no event shall Mejlerö Company GmbH be liable for any special, incidental, indirect, or consequential damages whatsoever, including, without limitation, damages for loss of profits, data, or other intangibles, arising out of the use or inability to use the Software.
`,
    ],
  },
  {
    text: "Governing Law",
    bullets: [
      `This Agreement shall be governed by and construed in accordance with the laws of Switzerland.`,
    ],
  },
  {
    text: "Changes to Terms",
    bullets: [
      `Mejlerö Company GmbH reserves the right to update or modify this Agreement at any time. Your continued use of the Software after any such changes constitutes your acceptance of the new terms.
`,
    ],
  },
  {
    text: "By using the Software, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this Agreement. If you do not agree to these terms, please refrain from using the Software. For questions or concerns regarding this Agreement, please contact Mejlerö Company GmbH at Contact us form.",
  },
];
export default terms;
