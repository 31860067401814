import { useEffect, useState } from "react";
export default function MyMailerLiteForm() {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <iframe
      style={{ border: 0 }}
      title="MailerLite Form"
      width="100%"
      height={screenWidth < 549 ? 350 : 250}
      src="https://dashboard.mailerlite.com/forms/476819/91504419071330206/share"
    ></iframe>
  );
}
