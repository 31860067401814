import React from "react";
import css from "./SectionFirst.module.css";
import logo from "../../../assets/logo.jpg";

export default function SectionFirst({ content }) {
  return (
    <div className={css.container}>
      <div className={css.logo}>
        <img src={logo} alt="logo"></img>
      </div>
      <h1 className={css.header}>{content.heading}</h1>
      <a href="#downloadApp" className={css.scrollToDownload}>Download app</a>
      <div className={css.imageContainer}>
        <div className={css.leftImg}>
          <img
            src={content.banner_images.left_img}
            alt="description"
            className={css.bannerLeftImg}
          />
        </div>
        <div className={css.mainImg}>
          <img
            src={content.banner_images.main_img}
            alt="description"
            className={css.bannerMainImg}
          />
        </div>
        <div className={css.rightImg}>
          <img
            src={content.banner_images.right_img}
            alt="description"
            className={css.bannerRightImg}
          />
        </div>
      </div>
    </div>
  );
}
