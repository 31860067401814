import css from "./PrivatePolicyScreen.module.css";
import policy from "../../privacyPolicy";
import Footer from "../../components/Footer/footer";
export default function PrivatePolicyScreen() {
  const renderpolicy = policy.map((item) => {
    return (
      <>
        {item.title && <h2 className={css.title}>{item.title}</h2>}
        {item.text && <p>{item.text}</p>}
        {item.bullets && (
          <ul>
            {item.bullets.map((bullet) => {
              return <li className={css.bullet}>{bullet}</li>;
            })}
          </ul>
        )}
      </>
    );
  });
  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h1 className={css.header}>Privacy Policy</h1>
      </div>
      <div className={css.textContainer}> {renderpolicy}</div>
      <Footer title="Home" path="/" />
    </div>
  );
}
