import Accordion from "react-bootstrap/Accordion";

function Acordion({ list }) {
  return (
    <Accordion defaultActiveKey="0">
      {list.map((item, index) => (
        <Accordion.Item key={index} eventKey={index.toString()}>
          <Accordion.Header>
            {item.header || `Accordion Item #${index + 1}`}
          </Accordion.Header>
          <Accordion.Body>
            {item.body || `Content for Accordion Item #${index + 1}`}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default Acordion;
