import React from 'react';
import './App.css';
import HomeScreen, { loader as HomePageLoader } from './Screens/HomeScreen/HomeScreen';
import PrivatePolicyScreen from './Screens/PrivatePolicyScreen/PrivatePolicyScreen';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import TermsScreen from './Screens/TermsScreen/TermsScreen';
import SupportScreen from './Screens/SupportScreen/SupportScreen';
import 'bootstrap/dist/css/bootstrap.min.css';

const router = createBrowserRouter([
  { 
    path: '/', 
    index: true, 
    element: <HomeScreen />, 
    loader: HomePageLoader 
  },
  { 
    path: '/PrivacyPolicy', 
    element: <PrivatePolicyScreen />, 
  }, { 
    path: '/TermsConditions', 
    element: <TermsScreen/>, 
  }
  , { 
    path: '/Support', 
    element: <SupportScreen/>, 
  }
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
