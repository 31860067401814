import css from "./LinkToStore.module.css";
import googlePlayButton from "./assets/googlePlayButton.svg";
import appStoreButton from "./assets/appStoreButton.svg";
import googleQRCode from "./assets/googleQRCode.svg";
import appleQRCode from "./assets/appleQRCode.svg";

interface linkToStoreContent {
  heading: string;
  description: string;
  feature_image: string;
}

export default function LinkToStore({
  content,
}: {
  content: linkToStoreContent;
}) {
  const [featureImage, linkToAppStore] = content.feature_image.split(",");

  const linkToGooglePlay =
    "https://play.google.com/store/apps/details?id=com.tmc.messo";

  return (
    <section className={css.appSection}>
      <div className={css.upperContainer}>
        <div className={css.text}>
          <h1>{content.heading}</h1>
          <p>{content.description}</p>
        </div>

        <div id='downloadApp' className={css.downloadApp}>
          <div className={css.linkButton}>
            <LinkButton
              href={linkToAppStore}
              src={appStoreButton}
              store='App Store'
            />
          </div>

          <div className={css.linkButton}>
            <LinkButton
              href={linkToGooglePlay}
              src={googlePlayButton}
              store='Google Play'
            />
          </div>

          <div className={css.qrCode}>
            <LinkButton
              href={linkToAppStore}
              src={appleQRCode}
              store='App Store'
            />
          </div>

          <div className={css.qrCode}>
            <LinkButton
              href={linkToGooglePlay}
              src={googleQRCode}
              store='Google Play'
            />
          </div>
        </div>
      </div>

      <div className={css.featureImage}>
        <img src={featureImage} alt='chat in messo' />
      </div>
    </section>
  );
}

function LinkButton({
  href,
  src,
  store,
}: {
  href?: string;
  src: string;
  store: string;
}) {
  return (
    <a href={href} className={css.link}>
      <img src={src} alt={`Download on the ${store}`} className={css.image} />
    </a>
  );
}
