import styles from './footer.module.css';

interface FooterItem {
  title: string;
  path: string;
}

const footerItems: FooterItem[] = [
  { title: "Home", path: "/" },
  { title: "Privacy Policy", path: "/PrivacyPolicy" },
  { title: 'Contact us', path: "mailto:andrei@ctotmc.com"},
  { title: 'Terms & Conditions', path: "/TermsConditions"},
  { title: 'Support', path: "/Support"},
];

export default function Footer() {
  return (
    <footer className={styles.footer}>
      {footerItems.map((item, index) => (
        <a key={index} href={item.path} className={styles.link}>
          {item.title}
        </a>
      ))}
    </footer>
  );
}