import { useLoaderData } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import MyMailerLiteForm from "../../components/NewsLettersForm/MyMailerLiteForm";
import {
  CMS_API_BASE_URL,
  CMS_API_HOMEPAGE_PATH,
} from "../../constants/Global";
import SectionFirst from "./SectionFist/SectionFirst";
import SectionSecond from "./SectionSecond/SectionSecond";
import LinkToStore from "./linkToStore/LinkToStore";

export default function HomeScreen() {
  const { data: responseData = {} } = useLoaderData();
  const { attributes: contentAttributes = {} } = responseData;
  const {
    welcomepage_header: headerContent = {},
    welcomepage_subsection_1: subSectionOne = {},
    welcomepage_subsection_2: linkToAppContent = {},
  } = contentAttributes;

  return (
    <>
      <SectionFirst content={headerContent}></SectionFirst>
      <MyMailerLiteForm></MyMailerLiteForm>
      <SectionSecond content={subSectionOne}></SectionSecond>
      <LinkToStore content={linkToAppContent} />
      <Footer title='Privacy Policy' path='/PrivacyPolicy' />
    </>
  );
}

export async function loader() {
  const response = await fetch(`${CMS_API_BASE_URL}/${CMS_API_HOMEPAGE_PATH}`);

  if (!response.ok) {
    console.log("Error fetching the welcomepage data");
  } else {
    return response;
  }
}
