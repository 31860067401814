import React from "react";
import css from "./SectionSecond.module.css";

export default function SectionSecond({ content }) {
  return (
    <div className={css.container}>
      <div className={css.imgContainer}>
        <img
           src={content.feature_image}
          alt="description"
          className={css.img}
        />
      </div>

      <div className={css.texContainer}>
        <h2 className={css.header}>{content.heading}</h2>

        {content.description &&
          content.description.split("\n\n").map(function (item, idx) {
            return (
              <p className={css.text} key={idx}>
                {item}
              </p>
            );
          })}
      </div>
    </div>
  );
}
