import css from "./TermsScreen.module.css";
import terms from "../../termsOfUse";
import Footer from "../../components/Footer/footer";
export default function TermsScreen() {
  const renderpolicy = terms.map((item) => {
    return (
      <>
        {item.title && <h2 className={css.title}>{item.title}</h2>}
        {item.text && <p>{item.text}</p>}
        {item.bullets && (
          <ul>
            {item.bullets.map((bullet) => {
              return <li className={css.bullet}>{bullet}</li>;
            })}
          </ul>
        )}
      </>
    );
  });
  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <h1 className={css.header}>Terms and conditions</h1>
      </div>
      <div className={css.textContainer}> {renderpolicy}</div>
      <Footer />
    </div>
  );
}
